import AboutMe from './AboutMe';
import Career from './Career';
import Contact from './Contact';
import './App.css';
import { useEffect } from 'react';

function App() {
    useEffect(() => {
        const handleScroll = () => {
            const transitionDiv = document.querySelector('.transition-effect');
            const careerDiv = document.querySelector('.career-container');
            const careerOffsetTop = careerDiv.offsetTop;
            const scrollPosition = window.pageYOffset + window.innerHeight;

            if (scrollPosition >= careerOffsetTop) {
                transitionDiv.classList.add('animate');
            } else {
                transitionDiv.classList.remove('animate');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <div className="nav-container">
            <header>
                <nav>
                    <ul className="menu">
                        <li><a href="#about">About</a></li>
                        <li><a href="#career">Career</a></li>
                        <li><a href="#contact">Contact Me</a>
                            <ul className="submenu">
                                <li><a href="#about">Currently</a></li>
                                <li><a href="#about">a</a></li>
                                <li><a href="#about">Work in Progress</a></li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </header>
            
            <main>
                <section id="about" className="section-about">
                    <AboutMe />
                </section>
                <div className="transition-effect"></div> {/* Transition Effect */}
                <section id="career" className="section-career">
                    <Career />
                </section>
                <div className="transition-effect"></div> {/* Transition Effect */}
                <section id="contact" className="section-contact">
                    <Contact />
                </section>
            </main>
        </div>
    );
}

export default App;

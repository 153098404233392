import React from 'react';
// import Bootstrap CSS if you haven't added it globally
 import 'bootstrap/dist/css/bootstrap.min.css';
 import { Envelope, Telephone, Linkedin, Github } from 'react-bootstrap-icons';
 import './Contact.css';
 import './App.css';
 
export default function Contact() {
  return (
    <div className="contact-container">
      <div className="row">
        <div className="col-md-6 d-flex justify-content-center">
          {/* Left side with the image or additional information */}
          <img src="/austinadam.jpeg" alt="Contact" className="img-fluid" />
        </div>
        <div className="col-md-6">
          {/* Right side with the contact form */}
          <h2>Contact Me</h2>
          <form>
            <div className="mb-3">
              <label htmlFor="fullName" className="form-label">Full Name</label>
              <input type="text" className="form-control" id="fullName" placeholder="Your Full Name" />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">E-mail</label>
              <input type="email" className="form-control" id="email" placeholder="Your Email" />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">Message</label>
              <textarea className="form-control" id="message" rows="3" placeholder="Your Message"></textarea>
            </div>
            <button type="submit" className="btn btn-primary">Send Message</button>
          </form>
          {/* Contact details */}
          <div className="contact-details">
            <p><strong>Email:</strong> austin.s.adam@gmail.com</p>
            <p><strong>Location:</strong> San Jose, California</p>
          </div>
          {/* Social media icons */}
          <div className="social-icons">
            <a href="https://www.linkedin.com/in/a-s-adam/" className="me" target="_blank" rel="noopener noreferrer">
            <Linkedin size={32} />
            </a>
            <a href="tel:+14088598570" className="me">
            <Telephone size={32} />
            </a>
            <a href="mailto:austin.s.adam@gmail.com" className="me" >
            <Envelope size={32} />
            </a>
            <a href="https://github.com/a-s-adam" className="me" target="_blank" rel="noopener noreferrer">
            <Github size={32} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
// Import Swiper React components and the core Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import SwiperCore from 'swiper';

// Import Swiper styles for Swiper components
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './App.css';
import './AboutMe.css';
// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default function AboutMe() {
  // Your images array
  const images = [
    '/carousel_images/summer2023.jpg',
    '/carousel_images/babyphoto-austin.jpg',
    // Add more image URLs as needed
  ];

  return (
    <div class = "aboutme-container">
      <h1>About Me</h1>
      <div className="content-container">
        <div className="carousel-container">
          <Swiper
            className="mySwiper"
            modules={[Navigation, Pagination, Scrollbar, A11y]} // Pass modules here
            spaceBetween={100}
            slidesPerView={1}
            navigation // Enable navigation
            pagination={{ clickable: true }} // Enable pagination
            scrollbar={{ draggable: true }} // Enable scrollbar
            loop={true} // Enable infinite looping
            a11y // Enable accessibility features
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <img src={image} alt={`Slide ${index}`} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div class="text-container">
          <p>This is an example <span class="highlight">highlighted text</span> in the paragraph.</p>
          <p>Another paragraph with <span class="highlight">important</span> words.</p>
          
        </div>
      </div>
    </div>
  );
}
